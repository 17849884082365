import React, { useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import { useIntl } from 'react-intl';

import ScrollableArea from '@kit/ScrollableArea';

import { useSearchContext } from './context/SearchContext';
import messages from './messages';
import styles from './SearchDropdown.module.css';
import SearchDropdownGroup from './SearchDropdownGroup';
import SearchDropdownItem from './SearchDropdownItem';
import SearchDropdownPlaceholder from './SearchDropdownPlaceholder';
import checkHasSearchData from './utils/checkHasSearchData';
import setAliasForDev from './utils/setAliasForDev';

import type { SearchDropdownProps } from './types';
import type { FC } from 'react';

const SearchDropdown: FC<SearchDropdownProps> = ({
    data,
    isLoading,
    onLoadMore,
    onItemClick,
}) => {
    const [currentHeight, setCurrentHeight] = useState(400);
    const { formatMessage } = useIntl();
    const { size } = useSearchContext();

    const scrollableRef = useRef(null);

    const hasData = checkHasSearchData(data);

    const {
        playersSearch,
        teamsSearch,
        gamesSearch,
        devSearch,
        servicesSearch,
        mediaSearch,
    } = data || {};

    useEffect(() => {
        if (!scrollableRef.current) {
            setCurrentHeight(400);

            return;
        }

        const { scrollHeight } = scrollableRef.current;

        setCurrentHeight(scrollHeight);
    }, [data]);

    return (
        <div className={cn(styles.root, { [styles.fullscreen]: size === 'fullscreen' })}>
            <ScrollableArea style={{ height: currentHeight }}>
                {!hasData || isLoading
                    ? <SearchDropdownPlaceholder isLoading={isLoading} />
                    : (
                        <div className={styles.scrollable} ref={scrollableRef}>
                            {playersSearch.searchData && playersSearch.searchData.items.length > 0 && (
                                <SearchDropdownGroup
                                    title={formatMessage(messages.groupLabelPlayers)}
                                    count={playersSearch.searchData.total}
                                    isLoading={playersSearch.isLoading}
                                    hasNextPage={playersSearch.hasNextPage}
                                    onLoadMore={() => onLoadMore?.('player')}
                                >
                                    {playersSearch.searchData.items.map((item, i) => (
                                        <SearchDropdownItem
                                            key={`${item.url}_${item.title}`}
                                            type="player"
                                            data={item}
                                            index={i}
                                            onClick={onItemClick}
                                        />
                                    ))}
                                </SearchDropdownGroup>
                            )}
                            {teamsSearch.searchData && teamsSearch.searchData.items.length > 0 && (
                                <SearchDropdownGroup
                                    title={formatMessage(messages.groupLabelTeams)}
                                    count={teamsSearch.searchData.total}
                                    isLoading={teamsSearch.isLoading}
                                    hasNextPage={teamsSearch.hasNextPage}
                                    onLoadMore={() => onLoadMore?.('team')}
                                >
                                    {teamsSearch.searchData.items.map((item, i) => (
                                        <SearchDropdownItem
                                            key={`${item.url}_${item.title}`}
                                            type="team"
                                            data={item}
                                            index={i}
                                            onClick={onItemClick}
                                        />
                                    ))}
                                </SearchDropdownGroup>
                            )}
                            {gamesSearch.searchData && gamesSearch.searchData.items.length > 0 && (
                                <SearchDropdownGroup
                                    title={formatMessage(messages.groupLabelGames)}
                                    count={gamesSearch.searchData.total}
                                    isLoading={gamesSearch.isLoading}
                                    hasNextPage={gamesSearch.hasNextPage}
                                    onLoadMore={() => onLoadMore?.('game')}
                                >
                                    {gamesSearch.searchData.items.map((item, i) => (
                                        <SearchDropdownItem
                                            key={`${item.url}_${item.title}`}
                                            type="game"
                                            data={item}
                                            index={i}
                                            onClick={onItemClick}
                                        />
                                    ))}
                                </SearchDropdownGroup>
                            )}
                            {servicesSearch.searchData && servicesSearch.searchData.items.length > 0 && (
                                <SearchDropdownGroup
                                    title={formatMessage(messages.groupLabelServices)}
                                    count={servicesSearch.searchData.total}
                                    isLoading={servicesSearch.isLoading}
                                >
                                    {servicesSearch.searchData.items.map((item, i) => (
                                        <SearchDropdownItem
                                            key={`${item.url}_${item.title}`}
                                            type="service"
                                            data={item}
                                            index={i}
                                            onClick={onItemClick}
                                        />
                                    ))}
                                </SearchDropdownGroup>
                            )}
                            {devSearch.searchData && devSearch.searchData.items.length > 0 && (
                                <SearchDropdownGroup
                                    title="DEV"
                                    count={devSearch.searchData.total}
                                    isLoading={devSearch.isLoading}
                                    hasNextPage={devSearch.hasNextPage}
                                    onLoadMore={() => onLoadMore?.('dev')}
                                >
                                    {devSearch.searchData.items.map((item, i) => (
                                        <SearchDropdownItem
                                            key={`${item.url}_${item.title}`}
                                            type="game"
                                            data={setAliasForDev(item)}
                                            index={i}
                                            onClick={onItemClick}
                                        />
                                    ))}
                                </SearchDropdownGroup>
                            )}
                            {mediaSearch.searchData && mediaSearch.searchData.items.length > 0 && (
                                <SearchDropdownGroup
                                    title={formatMessage(messages.groupLabelMedia)}
                                    count={mediaSearch.searchData.total}
                                    isLoading={mediaSearch.isLoading}
                                    hasNextPage={mediaSearch.hasNextPage}
                                    onLoadMore={() => onLoadMore?.('media')}
                                >
                                    {mediaSearch.searchData.items.map((item, i) => (
                                        <SearchDropdownItem
                                            key={`${item.url}_${item.title}`}
                                            type="media"
                                            data={item}
                                            index={i}
                                            onClick={onItemClick}
                                        />
                                    ))}
                                </SearchDropdownGroup>
                            )}
                        </div>
                    )}
            </ScrollableArea>
        </div>
    );
};

export default SearchDropdown;
