import React, { useState } from 'react';

import { inGamecenter, triggerPixel } from '@vkplay/shared';
import {
    Button, Dialog, Icon, Tooltip, useBreakpoints, WrapperButton,
} from '@vkplay/ui';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import { getNextNotification, sendButtonRequest } from '@components/TopNav/Notifications/helpers';
import messages from '@components/TopNav/Notifications/messages';

import styles from './Notification.module.css';

import type { NotificationActionsProps, NotificationControl } from '../types';
import type { ButtonProps } from '@vkplay/ui';
import type { SyntheticEvent } from 'react';

const buttonAppearance: {
    [key: string]: {
        appearance?: ButtonProps['appearance'];
        mode?: ButtonProps['mode'];
    };
} = {
    P: {
        appearance: 'overlay',
    },
    S: {
        appearance: 'neutral',
    },
    A: {
        appearance: 'overlay',
        mode: 'link',
    },
};

function NotificationActions({
    notification: {
        id: notificationId,
        controls,
        data: {
            pixel_click_url,
        },
    },
    inBubble = false,
    onClose,
}: NotificationActionsProps) {
    const [open, setOpen] = useState(false);
    const { tabletMdMax: isMobile } = useBreakpoints();
    const { formatMessage } = useIntl();

    const handleButtonClick = async (event: SyntheticEvent, control: NotificationControl) => {
        event.stopPropagation();

        if (pixel_click_url) {
            triggerPixel(pixel_click_url);
        }

        if (inBubble && onClose) {
            onClose(notificationId);
        }

        if (control.method === 'N') {
            const href = inGamecenter ? control.gc_link_url : control.link_url;

            window.open(href, '_blank')?.focus();
        }

        if (['P', 'G'].includes(control.method)) {
            await sendButtonRequest(control.method, control.link_url, control.authorization);
        }

        if (control.has_next_template) {
            await getNextNotification(notificationId, control.id);
        }
    };

    const mainButtons = controls.slice(0, 2);
    const secondaryButtons = controls.slice(2);

    const handleToggleDialog = () => {
        setOpen((prevState) => !prevState);
    };

    const renderSecondaryButtons = () => {
        if (secondaryButtons.length === 0) return null;

        const moreButtonProps: ButtonProps = {
            size: 'sm',
            leftIcon: <Icon name="burger" />,
            appearance: 'neutral',
            onClick: () => {},
        };

        if (isMobile && !inGamecenter) {
            return (
                <>
                    <Button
                        {...moreButtonProps}
                        onClick={() => setOpen(true)}
                    />

                    <Dialog
                        open={open}
                        onClose={handleToggleDialog}
                        className={styles.dialogMobile}
                        hideCloseIcon
                    >
                        <div
                            className="vkp-app-shell"
                            data-theme="dark"
                        >
                            {secondaryButtons.map((control) => (
                                <WrapperButton
                                    key={control.id}
                                    onClick={(event) => {
                                        handleButtonClick(event, control);
                                        handleToggleDialog();
                                    }}
                                    className={styles.contextMenuItem}
                                >
                                    <span>
                                        {control.name}
                                    </span>
                                </WrapperButton>
                            ))}

                            <Button
                                size="lg"
                                appearance="neutral"
                                onClick={handleToggleDialog}
                                className={styles.cancelContextMenuButton}
                            >
                                {formatMessage(messages.controlCancel)}
                            </Button>
                        </div>
                    </Dialog>
                </>
            );
        }

        const tooltipId = `more-buttons-tooltip-${notificationId}${inBubble ? '-inBubble' : ''}`;

        return (
            secondaryButtons.length > 1 ? (
                <>
                    <Button
                        {...moreButtonProps}
                        data-tooltip-id={tooltipId}
                    />
                    <Tooltip
                        id={tooltipId}
                        opacity={1}
                        clickable
                        place={inBubble ? 'right-end' : 'right-start'}
                        positionStrategy="fixed"
                        className={cn(styles.actionsTooltip, { [styles.gc]: inGamecenter })}
                    >
                        {secondaryButtons.map((control) => (
                            <Button
                                key={control.id}
                                size="sm"
                                {...buttonAppearance[control.type]}
                                onClick={(event) => handleButtonClick(event, control)}
                                className={styles.actionButton}
                            >
                                {control.name}
                            </Button>
                        ))}
                    </Tooltip>
                </>
            ) : secondaryButtons.map((control) => (
                <Button
                    key={control.id}
                    size="sm"
                    {...buttonAppearance[control.type]}
                    onClick={(event) => handleButtonClick(event, control)}
                    className={cn({
                        [styles.lastLink]: control.type === 'A',
                    })}
                >
                    {control.name}
                </Button>
            ))
        );
    };

    return (
        <div className={styles.actions}>
            {mainButtons.map((control) => (
                <Button
                    key={control.id}
                    size="sm"
                    {...buttonAppearance[control.type]}
                    onClick={(event) => handleButtonClick(event, control)}
                >
                    {control.name}
                </Button>
            ))}
            {renderSecondaryButtons()}
        </div>
    );
}

export default NotificationActions;
