import React from 'react';

import { Button, Icon } from '@vkplay/ui';
import { useIntl } from 'react-intl';

import { SOCIAL } from './constants';
import styles from './Footer.module.css';
import { anotherMessages } from './messages';

function FooterSocial() {
    const { formatMessage } = useIntl();

    return (
        <div className={styles.social}>
            <p className={styles.baseText}>
                {`${formatMessage(anotherMessages.social)}:`}
            </p>

            <div className={styles.socialButtons}>
                {SOCIAL.map((social) => (
                    <Button
                        key={social.icon}
                        component="a"
                        href={social.href}
                        target="_blank"
                        rel="noopener noreferrer"
                        appearance="neutral"
                        mode="primary"
                        size="lg"
                        leftIcon={<Icon name={social.icon} />}
                    />
                ))}
            </div>
        </div>
    );
}

export default FooterSocial;
