import React, { useContext } from 'react';

import { inGamecenter } from '@vkplay/shared';
import { Loader } from '@vkplay/ui';
import cn from 'classnames';

import NotificationsContext from '@components/TopNav/Notifications/context/NotificationsContext';
import EmptyList from '@components/TopNav/Notifications/EmptyList';
import Notification from '@components/TopNav/Notifications/Notification';
import styles from '@components/TopNav/Notifications/Notifications.module.css';
import NotificationsHeader from '@components/TopNav/Notifications/NotificationsHeader';

function NotificationsList() {
    const {
        list,
        unread,
        loading,
        nextPage,
    } = useContext(NotificationsContext);

    return (
        <>
            {!loading && list.length > 0 && (
                <NotificationsHeader
                    hasNew={unread !== 0}
                    loading={loading}
                />
            )}

            {loading && (
                <div className={cn(styles.loader, { [styles.gc]: inGamecenter })}>
                    <Loader size="48" />
                </div>
            )}

            {!loading && list.length > 0 ? (
                <div className={cn(styles.list)}>
                    {list.map((item) => (
                        <Notification
                            key={item.id}
                            notification={item}
                        />
                    ))}

                    {nextPage !== null && (
                        <Loader
                            size="48"
                        />
                    )}
                </div>
            ) : !loading && (
                <EmptyList />
            )}
        </>
    );
}

export default NotificationsList;
